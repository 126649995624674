<div class="alert alert-dismissible alert-danger d-flex">
  <span class="material-icons">report_problem</span>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="alert"
    (click)="snackBarRef.dismiss()"
  ></button>
  <div *ngIf="data && data.length > 0">
    <p class="alert-heading">
      {{ 'notifications.errorAlert.title' | translate }}
    </p>
    <ul>
      <li *ngFor="let error of errors">{{ error }}</li>
    </ul>
  </div>
</div>
