<div class="alert alert-dismissible alert-success d-flex">
  <span class="material-icons">check_circle</span>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="alert"
    (click)="snackBarRef.dismiss()"
  ></button>
  <div>
    <p class="alert-heading">Success</p>
    <p class="mb-0">{{ data }}</p>
  </div>
</div>
